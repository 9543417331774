<template>
  <div>
    <div class='jammed-columns jammed-is-multiline'>
      <div class='jammed-column jammed-is-12'>
        <h3 class='jammed-title jammed-is-3' v-if="!smallMode">Choose your time</h3>
        <div class='jammed-level jammed-is-mobile'>
          <div class='jammed-level-left' v-if="!smallMode">
            <div class='jammed-field'>
              <label class='jammed-label'>Date</label>
              <div class='jammed-control'>
                <input type='date' v-model='date' class='jammed-input' v-bind:class="{ 'jammed-is-medium': largeMode }">
              </div>
            </div>
          </div>

          <div class='jammed-level-item'>
            <div class='jammed-field'>
              <label class='jammed-label'>Start from</label>
              <div class='jammed-control'>
                <span class='jammed-select' v-bind:class="{ 'jammed-is-medium': !smallMode }">
                  <select v-model='start_time'>
                    <option
                      v-for='time in available_start_times'
                      :value='time'
                      :key='time'>
                      {{ formattedTime(time) }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>

          <div class='jammed-level-item'>
            <div class='jammed-field'>
              <label class='jammed-label'>Duration</label>
              <div class='jammed-control'>
                <span class='jammed-select' v-bind:class="{ 'jammed-is-medium': !smallMode }">
                  <select v-model='duration'>
                    <option
                      v-for='hour in available_durations'
                      :value='hour'
                      :key='hour'>
                      {{ hour }} hours
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>
        </div>
        <today-block-chart
          :start-time='start_time'
          :duration='duration'
          :time-array='timeArray'
          :smallMode="smallMode"
          :largeMode="largeMode"
          :booking-times='booking_times'
          :twelve-hour-times='twelveHourTimes'
          v-on:change-start-time='changeStartTime' />
        <tomorrow-block-chart
          v-if='crosses_next_day'
          :start-time='tomorrowStartTime'
          :duration='duration_in_next_day'
          :time-array='timeArray'
          :smallMode="smallMode"
          :largeMode="largeMode"
          :booking-times='tomorrow_booking_times'
          :twelve-hour-times='twelveHourTimes'
          v-on:change-start-time='changeToTomorrowStartTime' />
      </div>
      <div class='jammed-column jammed-is-12' v-if='loaded'>
        <div v-if='available'>
          <h3 class='jammed-title jammed-is-3'>Booking details</h3>
          <h4 class='jammed-subtitle jammed-is-5 jammed-mb-2'>{{selectedRoom.name}}: {{ formattedTime(start_time) }} for {{duration}} hours</h4>
          <p class='jammed-subtitle jammed-is-5'>{{ formattedDate }}</p>
          <p>This time is available</p>
          <p class="jammed-mb-3" v-if="selectedRoom.space_for">
            <span v-if="selectedRoom.space_for == 1">
              Space for one person
            </span>
            <span v-else>
              Space for {{ selectedRoom.space_for }} people
            </span>
            <br>
          </p>
          <h4 class='jammed-title jammed-is-4'>Cost: {{price_string}}</h4>
          <button class='jammed-button jammed-is-large jammed-is-success jammed-is-fullwidth' v-bind:class='{ "jammed-is-loading": is_booking }' @click='reserve'>
            Reserve this {{ selectedRoom.space_type }}
          </button>
        </div>

        <div v-else>
          <h3 class='jammed-title jammed-is-3'>This time is not available</h3>
          <p class='jammed-subtitle jammed-is-5'>Please look for other times on the left</p>
        </div>
      </div>
      <div class='jammed-column jammed-is-4' v-bind:class="{ 'jammed-is-12': smallMode }" v-else>
        <b-skeleton width='40%' height='50px' :animated='true'></b-skeleton>
        <b-skeleton width='70%' height='30px' :animated='true'></b-skeleton>
        <b-skeleton width='40%' :animated='true'></b-skeleton>
        <b-skeleton width='40%' :animated='true'></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script type='text/javascript'>
import timesApi from '../api/times.js'
import holdApi from '../api/bookingHold.js'
import TodayBlockChart from './TodayBlockChart.vue'
import TomorrowBlockChart from './TomorrowBlockChart.vue'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export default {
  name: 'TwentyFourHourSelector',
  components: { TodayBlockChart, TomorrowBlockChart },
  props: {
    apiPath: {
      type: String,
      default: ''
    },
    selectedDate: {
      type: String,
      default: ''
    },
    selectedRoom: {
      type: Object,
      default() {
        return {}
      }
    },
    selectedActivity: {
      type: Object,
      default() {
        return {}
      }
    },
    smallMode: {
      type: Boolean,
      default: false
    },
    largeMode: {
      type: Boolean,
      default: false
    },
    timeArray: {
      type: Array,
      default() {
        return []
      }
    },
    twelveHourTimes: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loaded: false,
      available_start_times: [],
      available_durations: [],
      booking_times: [],
      tomorrow_booking_times: [],

      duration: 4,
      start_time: '',
      date: '',

      available: false,
      is_booking: false,
      crosses_next_day: false,
      duration_in_next_day: 0,
      price_string: ''
    }
  },
  mounted() {
    this.date = dayjs(this.selectedDate,'YYYYMMDD').format('YYYY-MM-DD')
    this.getInitialTimes()
  },
  watch: {
    duration() {
      if (!this.loaded) return
      this.fetchTimes()
    },
    start_time() {
      if (!this.loaded) return
      this.fetchTimes()
    },
    date() {
      if (!this.loaded) return
      this.fetchTimes()
    },
    selectedActivity() {
      if (!this.loaded) return
      this.fetchTimes()
    },
    selectedRoom() {
      if (!this.loaded) return
      this.fetchTimes()
    }
  },
  computed: {
    tomorrowStartTime() {
      return '00:00'
    },
    tomorrow() {
      return dayjs(this.date).add(1, 'day')
    },
    formattedDate() {
      return dayjs(this.date).format('ddd DD MMMM YYYY')
    },
    holdData() {
      if (this.selectedActivity) {
        return this.holdDataWithSession
      } else {
        return this.holdDataWithoutSession
      }
    },
    holdDataWithSession() {
      return {
        booking_hold: {
          room: this.selectedRoom.code,
          duration: this.duration,
          start_time: this.start_time,
          date: this.date,
          session: this.selectedActivity.name
        }
      }
    },
    holdDataWithoutSession() {
      return {
        booking_hold: {
          room: this.selectedRoom.code,
          duration: this.duration,
          start_time: this.start_time,
          date: this.date
        }
      }
    },
    selectedRoomSpaceType() {
      const raw = this.selectedRoom.space_type
      return raw[0].toUpperCase() + raw.slice(1)
    }
  },
  methods: {
    getInitialTimes() {
      this.loaded = false
      const reqData = {
        date: this.date,
        room: this.selectedRoom.code
      }
      timesApi.getAllDayTimes(this.apiPath, reqData, data => {
        this.available = data.available
        this.available_start_times = data.available_start_times
        this.start_time = data.available_start_times[0]
        this.booking_times = data.booking_times
        this.loaded = true
      })
    },
    changeStartTime(time) {
      this.start_time = time
    },
    changeToTomorrowStartTime(time) {
      this.start_time = time
      this.date = this.tomorrow.format('YYYY-MM-DD')
    },
    formattedTime(time) {
      const twelve_hour_format = 'h:mma'
      if (this.twelveHourTimes) {
        return dayjs(time, 'HH:mm').format(twelve_hour_format)
      } else {
        return time
      }
    },
    fetchTimes() {
      this.loaded = false
      let reqData = {
        date: this.date,
        room: this.selectedRoom.code,
        start_time: this.start_time,
        duration: this.duration
      }
      if (this.selectedActivity) {
        reqData.session = this.selectedActivity.name
      }
      timesApi.getAllDayTimes(this.apiPath, reqData, data => {
        this.available = data.available
        this.price_string = data.price_string
        this.available_start_times = data.available_start_times
        this.crosses_next_day = data.crosses_next_day
        this.duration_in_next_day = data.duration_in_next_day
        this.available_durations = data.available_durations

        if (!this.available_durations.includes(this.duration)) {
          this.duration = this.available_durations[0]
        }

        this.booking_times = data.booking_times
        this.loaded = true
      })

      if (this.crosses_next_day) {
        const nextDayBookingData = {
          date: this.tomorrow.format('YYYY-MM-DD'),
          room: this.selectedRoom.code
        }

        timesApi.getAllDayTimes(this.apiPath, nextDayBookingData, data => {
          this.tomorrow_booking_times = data.booking_times
        })
      }
    },
    reserve() {
      holdApi.holdSlotWithTimes(this.apiPath, this.holdData, response => {
        window.location = response.reservation_url
      })
    }
  }
}

</script>
