<template>
  <div id="JammedBookingApp--Calendar">
    <datepicker
      :inline="true"
      :disabled-dates="disabledDays"
      @selected="dateSelected"
      calendar-class="JammedBookingApp--CalendarInner"
      :full-month-name="true"
      maximum-view="month"
      first-day-of-week="mon"
    ></datepicker>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Datepicker from '@sum.cumo/vue-datepicker'

export default {
  name: 'Calendar',
  components: { Datepicker },
  props: {
    unavailableDays: {
      type: Array,
      default() {
        return []
      }
    },
    bookingHorizonInMonths: {
      type: Number,
      optional: true,
      default: 6
    }
  },
  computed: {
    disabledDays() {
      return {
        to: dayjs().subtract(1, 'day').toDate(),
        from: dayjs().add(this.bookingHorizonInMonths, 'month').toDate(),
        dates: this.unavailableDates
      }
    },

    unavailableDates() {
      return this.unavailableDays.map(day => dayjs(day).toDate())
    }
  },
  methods: {
    dateSelected: function (thing) {
      const fomatted_date = dayjs(thing).format('YYYYMMDD')
      this.$emit('selected-date', fomatted_date)
    }
  }
}
</script>

<style lang='scss'>
@import '~@sum.cumo/vue-datepicker/src/styles/style.scss';

#JammedBookingApp {
  &.x-small {
    .JammedBookingApp--CalendarInner {
      .cell {
        height: 30px !important;
        line-height: 30px !important;
        font-size: 15px;
      }

      .day-header {
        font-size: 16px;
        font-weight: bold !important;
      }

      .day__month_btn {
        font-size: 16px;
      }
    }
  }
  &.small {
    .JammedBookingApp--CalendarInner {
      .cell {
        height: 50px !important;
        line-height: 50px !important;
        font-size: 20px;
      }

      .day-header {
        font-size: 18px;
        font-weight: bold !important;
      }

      .day__month_btn {
        font-size: 18px;
      }
    }
  }
  &.normal {
    .JammedBookingApp--CalendarInner {
      .cell {
        height: 70px !important;
        line-height: 70px !important;
        font-size: 22px;
      }

      .day-header {
        font-size: 20px;
        font-weight: bold !important;
      }

      .day__month_btn {
        font-size: 22px;
      }
    }
  }
  &.large {
    .JammedBookingApp--CalendarInner {
      .cell {
        height: 80px !important;
        line-height: 80px !important;
        font-size: 26px;
      }

      .day-header {
        font-size: 22px;
        font-weight: bold !important;
        margin: 15px 0;
      }

      .day__month_btn {
        font-size: 26px;
      }
    }
  }
}

.JammedBookingApp--CalendarInner {
  width: 100% !important;
  border: none !important;

  .cell {
    font-weight: 700 !important;
  }

  .cell.day.disabled {
    color: rgba(0,0,0, 0.54);
    font-weight: 200 !important;
  }

  .day-header {
    font-weight: bold !important;
  }

  .cell.selected {
    background-color: #00d1b2 !important;
    color: white !important;
    font-weight: bold;
  }

  .cell:not(.blank):not(.disabled).day:hover,
  .cell:not(.blank):not(.disabled).month:hover,
  .cell:not(.blank):not(.disabled).year:hover {
    border: solid 1px #00d1b2 !important;
  }
}

</style>
