var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"jammed-has-background-light",class:{
    'jammed-has-text-grey-lighter': !_vm.available,
    'jammed-is-invisible jammed-is-hidden-mobile': _vm.invisible
    }},[_c('p',{class:{
      'jammed-is-size-4': _vm.largeMode,
      'jammed-is-size-5': !_vm.largeMode && !_vm.smallMode,
      'jammed-is-size-6': _vm.smallMode,
      'jammed-pb-2 jammed-pt-2': !_vm.smallMode && _vm.doubleHeight,
    }},[_vm._v(_vm._s(_vm.time))]),(!_vm.smallMode)?_c('p',{staticClass:"jammed-is-size-7"},[_vm._v(_vm._s(_vm.price))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }