<template>
  <div>
    <loading :active.sync="loading" :container="this.$refs.jammedContainer"></loading>
    <div class="rooms jammed-columns">
      <div class="jammed-column" v-bind:class="{ 'jammed-is-5-desktop jammed-is-6-tablet': showImage }" v-if="availableRooms">
        <aside class="jammed-menu">
          <slot />
          <ul
            class="jammed-menu-list"
            v-bind:class="{'jammed-columns jammed-is-multiline': smallMode}"
            >
            <li
              v-for="room in availableRooms"
              :key="room.code"
              v-bind:class="{'jammed-column jammed-is-6-tablet jammed-is-12-mobile': smallMode}"
              >
            <a
              class="jammed-has-text-weight-bold"
              @click="selectRoom(room)"
              v-bind:value="room"
              v-bind:class="{
                'jammed-is-active jammed-has-text-white-bis': isSelectedRoom(room),
                'jammed-has-background-light': !isSelectedRoom(room),
                'jammed-is-size-5': !smallMode,
                'jammed-is-size-6': smallMode
                }">
              <div class="jammed-p-1 jammed-is-hidden-mobile">
                <p>{{room.name}}</p>
                <span class="jammed-has-text-weight-light jammed-is-size-6">From {{ room.from }}</span>
              </div>
              <div class="jammed-is-hidden-tablet">
                <p>{{room.name}}</p>
                <span class="jammed-has-text-weight-light  jammed-is-size-6">From {{ room.from }}</span>
              </div>
            </a>
            </li>
          </ul>
        </aside>
      </div>
      <div class="jammed-column" v-if="showImage">
        <div>
          <figure class="jammed-image jammed-is-4by3">
            <img :src="image.original" :alt="image.name">

            <div class="jammed-block">
              <h3 class="jammed-title jammed-is-size-3-desktop jammed-is-size-4-touch">{{ selectedRoom.name }}</h3>
              <p class="jammed-subtitle jammed-is-size-5-desktop jammed-is-size-6-touch jammed-mb-0" v-if="selectedRoom.space_for">
                <span v-if="selectedRoom.space_for == 1">
                  {{ selectedRoomSpaceType }} space for one person
                </span>
                <span v-else>
                  {{ selectedRoomSpaceType }} space for {{ selectedRoom.space_for }} people
                </span>
                <br>
              </p>
              <p class="jammed-subtitle jammed-is-6-desktop jammed-is-size-7-touch" v-if="size == 'normal'">
                {{ truncatedRoomDescriptions[0] }}
              </p>
              <p class="jammed-subtitle jammed-is-6-desktop jammed-is-size-7-touch" v-else-if="size == 'large'">
                {{ truncatedRoomDescriptions[1] }}
              </p>
            </div>
          </figure>
        </div>
      </div>
    </div>

    <activity-picker
      v-if="selectedRoom && selectedActivity"
      :selected-activity="selectedActivity"
      :all-activities="allActivities"
      :available-activities="availableActivities"
      v-on:selected-activity="selectActivity" />

    <section
      class="jammed-has-text-black-ter"
      v-bind:class="{ 'time-picker': !smallMode }"
      v-if="roomIs247"
      >
      <twenty-four-hour-selector
        :apiPath="apiPath"
        :smallMode="smallMode"
        :selected-activity="selectedActivity"
        :largeMode="largeMode"
        :selectedDate="date"
        :selectedRoom="selectedRoom"
        :timeArray="timeArray24Hour"
        :twelveHourTimes="websiteInfo.twelve_hour_times" />
    </section>

    <section
      class="jammed-has-text-black-ter jammed-has-text-centered"
      v-bind:class="{ 'time-picker': !smallMode }"
      v-else>
      <div class="jammed-columns">
        <div class="jammed-column jammed-is-half">
          <h3 class="jammed-title" v-bind:class="{ 'jammed-is-4': !smallMode, 'jammed-is-6': smallMode }" v-if="!selectedStartTime">Pick a start time</h3>
          <h3 class="jammed-title" v-bind:class="{ 'jammed-is-4': !smallMode, 'jammed-is-6': smallMode }" v-else>Start time</h3>
          <h4 class="jammed-subtitle" v-bind:class="{ 'jammed-is-5': !smallMode, 'jammed-is-6': smallMode }" v-if="selectedStartTime && !smallMode">{{ formattedTime(selectedStartTime) }}</h4>
          <h4 class="jammed-subtitle" v-bind:class="{ 'jammed-is-5': !smallMode, 'jammed-is-6': smallMode }" v-else-if="!smallMode">&nbsp;</h4>
          <section>
            <div class="jammed-columns jammed-is-multiline jammed-is-mobile">
              <div
                class="jammed-column jammed-is-4-mobile jammed-is-3-tablet jammed-is-unselectable"
                :data-cy-start-time="startTime.time"
                @click="selectStartTime(startTime.time)"
                v-bind:class="{'selected': selectedStartTime == startTime.time, 'disabled-time': !startTime.available, 'time': startTime.available}"
                v-for="startTime in startTimes"
                :key="startTime.time"
              >
                <time-element :time="formattedTime(startTime.time)" :smallMode="smallMode" :largeMode="largeMode" :available="startTime.available" :doubleHeight="true" />
              </div>
            </div>
          </section>
        </div>

        <div id="end-times"></div>
        <div class="jammed-column jammed-is-half" v-if="selectedStartTime != ''">
          <h3 class="jammed-title" v-bind:class="{ 'jammed-is-4': !smallMode, 'jammed-is-6': smallMode }" v-if="!selectedEndTime">Pick an end time</h3>
          <h3 class="jammed-title" v-bind:class="{ 'jammed-is-4': !smallMode, 'jammed-is-6': smallMode }" v-else>End time</h3>
          <h4 class="jammed-subtitle" v-bind:class="{ 'jammed-is-5': !smallMode, 'jammed-is-6': smallMode }" v-if="selectedEndTime && !smallMode">{{ formattedTime(selectedEndTime) }}</h4>
          <h4 class="jammed-subtitle" v-bind:class="{ 'jammed-is-5': !smallMode, 'jammed-is-6': smallMode }" v-else-if="!smallMode">&nbsp;</h4>
          <section>
            <div class="jammed-columns jammed-is-multiline jammed-is-mobile">
              <div
                class="jammed-column jammed-is-4-mobile jammed-is-3-tablet jammed-is-unselectable"
                :data-cy-end-time="endTime.time"
                @click="selectEndTime(endTime.time)"
                v-bind:class="{'selected': selectedEndTime == endTime.time, 'disabled-time': !endTime.available || hideEndTime(endTime.time), 'time': endTime.available}"
                v-for="endTime in endTimes"
                :key="endTime.time"
              >
                <time-element :time="formattedTime(endTime.time)" :available="endTime.available" :smallMode="smallMode" :largeMode="largeMode" :invisible="hideEndTime(endTime.time)" :price="endTime.price" :doubleHeight="!endTime.price" />
              </div>
            </div>
          </section>
        </div>
      </div>
      <div v-if="withinGracePeriod">
        <div class="jammed-notification">
          <div class="jammed-content jammed-is-size-4" v-html="websiteInfo.grace_period_instructions" />
          <a class="jammed-button jammed-is-large jammed-is-info jammed-mr-3" @click="pickAnotherTime">Pick another time</a>
          <a class="jammed-button jammed-is-large jammed-is-info" :href="callHref">Call us {{ websiteInfo.telephone }}</a>
        </div>
      </div>
      <div v-else>
        <div v-if="timeBandsSelected">
          <button
            class="jammed-button jammed-is-success jammed-is-fullwidth"
            v-bind:class="{ 'jammed-is-large': !smallMode, 'jammed-is-normal': smallMode }"
            @click.once="reserve">
              <span v-if="largeMode">Reserve {{ formattedTime(selectedStartTime) }} - {{ formattedTime(selectedEndTime) }} in {{ truncate(selectedRoom.name, 40)}}</span>
              <span v-else>Reserve</span>
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)

import Loading from 'vue-loading-overlay'

import uniq from 'lodash.uniq'
import TimeElement from './TimeElement.vue'
import TwentyFourHourSelector from './TwentyFourHourSelector.vue'
import ActivityPicker from './ActivityPicker.vue'

import timesApi from '../api/times.js'
import holdApi from '../api/bookingHold.js'

export default {
  name: 'TimeSelector',
  data() {
    return {
      loading: false,
      withinGracePeriod: false,
      rooms: [],
      times: [],
      selectedRoom: '',
      selectedStartTime: '',
      selectedEndTime: '',
      selectedActivity: null
    }
  },
  props: {
    apiPath: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    websiteInfo: {
      type: Object,
      default: () => ({})
    },
    timeArrays: {
      type: Object,
      default() {
        return {
          openingTimeSlots: {
            thirtymin: [],
            hour: [],
          },
          twentyFourHourOpeningTimeSlots: {
            thirtymin: [],
            hour: []
          }
        }
      }
    }
  },
  components: { TimeElement, TwentyFourHourSelector, ActivityPicker, Loading },
  mounted() {
    this.getRoomsAndSetDefaultTimes()
  },
  watch: {
    selectedRoom() {
      this.selectedStartTime = ''
      this.selectedEndTime = ''

      this.getRooms()
      this.setActivityBasedOnAvailable()
    },
    // eslint-disable-next-line
    selectedActivity() {
      if (!this.availableStartTimes.includes(this.selectedStartTime) || !this.availableStartTimes.includes(this.selectedEndTime)) {
        this.selectedStartTime = ''
        this.selectedEndTime = ''
      }
    },
  },
  computed: {
    image() {
      if (!this.selectedRoom.images) return

      return this.selectedRoom.images[0]
    },
    showImage() {
      return this.selectedRoom && !!this.image && !this.smallMode
    },
    smallMode() {
      return this.size == 'small' || this.size == 'x-small'
    },
    largeMode() {
      return this.size == 'large'
    },
    timeArray() {
      if (this.selectedRoom && this.selectedRoom.slot_length == 1800) {
        return this.timeArrays.openingTimeSlots.thirtymin
      } else {
        return this.timeArrays.openingTimeSlots.hour
      }
    },
    timeArray24Hour() {
      if (this.selectedRoom && this.selectedRoom.slot_length == 1800) {
        return this.timeArrays.twentyFourHourOpeningTimeSlots.thirtymin
      } else {
        return this.timeArrays.twentyFourHourOpeningTimeSlots.hour
      }
    },
    roomIs247() {
      if (!this.selectedRoom) return

      return this.selectedRoom.twenty_four_hour_booking
    },
    showSessions() {
      if (!this.selectedRoom) return

      return this.selectedRoom.sessions && this.selectedRoom.sessions.length > 1
    },
    availableActivities() {
      return this.selectedRoom.sessions
    },
    allActivities() {
      return this.websiteInfo.booking_activities
    },
    availableRooms() {
      if (!this.rooms) return

      return this.rooms.filter((room) => { return room.available })
    },
    filteredEndTimes() {
      var times = this.times

      if (this.selectedActivity) {
        times = times.filter(slot => this.selectedActivity.name == slot.session)
      }

      if (this.selectedStartTime != '') {
        times = times.filter(slot => this.selectedStartTime == slot.startsAt)
      }

      return times
    },
    filteredStartTimes() {
      var times = this.times

      if (this.selectedActivity) {
        times = times.filter(slot => this.selectedActivity.name == slot.session)
      }

      return times
    },
    availableStartTimes() {
      const times = this.filteredStartTimes.map( (time) => time.startsAt )
      const raw_sorted = uniq(times).sort()
      return this.timeArray.filter( (time) => raw_sorted.indexOf(time) >= 0 )
    },
    availableEndTimes() {
      const times = this.filteredEndTimes.map( (time) => time.endsAt )
      const raw_sorted = uniq(times).sort()
      return this.timeArray.filter( (time) => raw_sorted.indexOf(time) >= 0 )
    },
    startTimes() {
      const firstAvailableStartTime = this.timeArray.indexOf(
        this.availableStartTimes[0]
      )
      const lastAvailableStartTime = this.timeArray.indexOf(
        this.availableStartTimes[this.availableStartTimes.length - 1]
      )
      const allSelectableStartTimes = this.timeArray.slice(
        firstAvailableStartTime,
        lastAvailableStartTime + 1
      )

      let startTimes = []
      allSelectableStartTimes.map(time => {
        startTimes.push({
          time: time,
          available: this.availableStartTimes.includes(time)
        })
      })
      return startTimes
    },
    endTimes() {
      const lastAvailableEndTime = this.timeArray.indexOf(
        this.availableEndTimes[this.availableEndTimes.length - 1]
      )

      const firstAvailableStartTime = this.timeArray.indexOf(
        this.availableStartTimes[0]
      )
      const allSelectableEndTimes = this.timeArray.slice(
        firstAvailableStartTime,
        lastAvailableEndTime + 1
      )

      let endTimes = []
      allSelectableEndTimes.map(time => {
        const price = this.filteredEndTimes.find((slot) => { return time == slot.endsAt })
        endTimes.push({
          time: time,
          price: price ? price.priceString : '',
          available: this.availableEndTimes.includes(time)
        })
      })
      return endTimes
    },
    timeString() {
      return `${this.formattedTime(this.selectedStartTime)} - ${this.formattedTime(this.selectedEndTime)}`
    },
    timeBandsSelected() {
      if (this.selectedStartTime == '' || this.selectedEndTime == '') return

      return this.filteredEndTimes.filter(
        time =>
          time.startsAt == this.selectedStartTime &&
          time.endsAt == this.selectedEndTime
      )
    },
    callHref() {
      return `tel:${this.websiteInfo.telephone}`
    },
    hiddenEndTimes() {
      if (this.selectedStartTime == '' || this.selectedEndTime == '') return

      const firstAvailableStartTime = 0
      const lastAvailableEndTime = this.timeArray.indexOf(
        this.selectedStartTime
      )
      return this.timeArray.slice(
        firstAvailableStartTime,
        lastAvailableEndTime
      )
    },
    selectedRoomSpaceType() {
      if (!this.selectedRoom) return

      const raw = this.selectedRoom.space_type
      return raw[0].toUpperCase() + raw.slice(1)
    },
    truncatedRoomDescriptions() {
      return [
        this.truncatedRoomDescription(30).replace(/<br\/>/g, ' '),
        this.truncatedRoomDescription(50).replace(/<br\/>/g, ' ')
      ]
    }
  },
  methods: {
    truncatedRoomDescription(length) {
      const description = this.selectedRoom.description
      if (!description || description === 'undefined') {
        return ''
      }

      return this.truncate(description, length)
    },
    truncate(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...'
      } else {
        return text
      }
    },
    getRooms() {
      if (this.roomIs247) {
        this.setActivityBasedOnAvailable()
        return
      }

      this.loading = true
      const reqData = { date: this.date, room: this.selectedRoom.code }
      timesApi.getTimes(this.apiPath, reqData, times => {
        this.times = times
        this.setActivityBasedOnAvailable()
        this.loading = false
      })
    },
    getRoomsAndSetDefaultTimes() {
      this.loading = true
      timesApi.getTimes(this.apiPath, { date: this.date }, rooms => {
        this.rooms = rooms
        this.setDefaultRoom(rooms)
        if (!this.roomIs247) {
          const reqData = { date: this.date, room: this.selectedRoom.code }
          timesApi.getTimes(this.apiPath, reqData, times => {
            this.times = times
            this.loading = false
          })
        } else {
          this.loading = false
        }
      })
    },
    setDefaultRoom(rooms) {
      this.selectedRoom = rooms.filter((room) => { return room.available })[0]
    },
    isSelectedRoom(room) {
      return this.selectedRoom == room
    },
    setActivityBasedOnAvailable() {
      if (this.availableActivities.length == 1) {
        const activityName = this.availableActivities[0]
        const activity = this.allActivities.find((activity) => { return activity.name == activityName })
        this.selectedActivity = activity
      } else if (this.allActivities.length > 1) {
        const defaultActivity = this.allActivities.find(a => a.default)
        if (this.availableActivities.includes(defaultActivity.name)) {
          this.selectedActivity = defaultActivity
        } else {
          const firstActivity = this.availableActivities[0]
          const activity = this.allActivities.find((activity) => { return activity.name == firstActivity })
          this.selectedActivity = activity
        }
      }
    },
    selectStartTime(time) {
      if (!this.availableStartTimes.includes(time)) return

      if (this.selectedStartTime == time) {
        this.selectedStartTime = ''
      } else {
        this.selectedStartTime = time
      }

      // If the end time previously chosen is now unavailable
      if (!this.availableEndTimes.includes(this.selectedEndTime)) {
        this.selectedEndTime = this.availableEndTimes[0] // Choose the first available end time
      }
    },
    selectEndTime(time) {
      if (!this.availableEndTimes.includes(time)) return

      if (this.selectedEndTime == time) {
        this.selectedEndTime = ''
      } else {
        this.selectedEndTime = time
      }
    },
    hideEndTime(time) {
      if (!this.hiddenEndTimes) return
      return this.hiddenEndTimes.indexOf(time) > -1
    },
    selectRoom(room) {
      if (this.selectedRoom == room) return

      this.selectedRoom = room
    },
    pickAnotherTime() {
      this.selectedStartTime = ''
      this.selectedEndTime = ''
      this.timeBandsSelected = false
      this.withinGracePeriod = false
    },
    selectActivity(givenActivity) {
      const activity = this.allActivities.find((activity) => { return activity.name == givenActivity.name })
      this.selectedActivity = activity
    },
    formattedTime(time) {
      const twelve_hour_format = 'h:mma'
      if (this.websiteInfo.twelve_hour_times) {
        return dayjs(time, 'HH:mm').format(twelve_hour_format)
      } else {
        return time
      }
    },
    reserve() {
      if (!this.timeBandsSelected) return
      this.loading = true
      const slotId = this.timeBandsSelected[0].slotId

      holdApi.holdSlot(this.apiPath, { slot_id: slotId }, response => {
        this.loading = false
        if (response.within_grace_period) {
          this.withinGracePeriod = true
        } else {
          window.location = response.reservation_url
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.columns.is-multiline {
  flex-wrap: wrap;
}

.columns:last-child {
  margin-bottom: -0.75rem;
}

.columns {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
    margin-top: -0.75rem;
}

aside {
  display: block;
}

.time,
.disabled-time {
  padding: 0.25em;
}

.time {
  cursor: pointer;
}

.time div,
.disabled-time div {
  padding: 0.1em;
}

.time:hover {
  background-color: hsl(0, 0%, 93%);
}

.time.disabled-time:hover {
  background-color: initial;
}

.time.selected div {
  color: white;
  background-color: #00d1b2 !important;
}

.time-picker {
  margin: 0 0.5rem 0.5rem;
}

.room input {
  display: none;
}

figure {
  position: relative
}

figure div {
  padding: 1.25rem 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(255,255,255,0.8);
}
</style>
