<template>
  <main id="JammedBookingApp" ref="jammedContainer" v-bind:class="[containerClasses, size]">
    <loading :active.sync="loading" :container="this.$refs.jammedContainer"></loading>
    <div class="jammed-notification jammed-is-danger" v-if="error">
      {{ error }}
    </div>
    <div v-else>
      <div id="JammedBookingApp--DaySelector" v-show="step==1">
        <h2 class="jammed-title jammed-is-4 jammed-has-text-centered" v-bind:class="{ 'jammed-is-6': size == 'x-small'}" v-if="introText ">
          {{ introText }}
        </h2>
        <calendar
          :unavailable-days="unavailableDays"
          :bookingHorizonInMonths="bookingHorizonInMonths"
          v-on:selected-date="selectedDate"
        />
      </div>
      <div id="JammedBookingApp--TimePicker" v-if="step==2">
        <time-selector
          :size="size"
          :date="date"
          :website-info="websiteInfo"
          :api-path="apiPath"
          :time-arrays="timeArrays"
        >
          <div>
            <div class="jammed-tags jammed-has-addons">
              <span class="jammed-tag jammed-is-medium">{{ friendlyDate }}</span>
              <a class="jammed-tag jammed-is-medium jammed-is-info" @click.once="changeDate">Change date</a>
            </div>
          </div>
        </time-selector>
      </div>
    </div>
  </main>
</template>

<script>
import Calendar from './components/Calendar.vue'
import TimeSelector from './components/TimeSelector.vue'
import Loading from 'vue-loading-overlay'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)

const opening_time_slots = {
  thirtymin: ['03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30','22:00','22:30','23:00','23:30','00:00','00:30','01:00','01:30','02:00','02:30'],
  hour: ['03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00','00:00','01:00','02:00']
}
const twenty_four_hour_opening_time_slots = {
  thirtymin: ['00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30','22:00','22:30','23:00','23:30'],
  hour: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00','23:00']
}

export default {
  name: 'App',
  props: {
    account: {
      type: String,
      default: 'demo'
    },
    mode: {
      type: String,
      default: 'live'
    },
    wrapper: {
      type: String,
      default: 'box'
    },
    introText: {
      type: String,
      default: 'Pick a date to get started...'
    }
  },
  data() {
    return {
      step: 1,
      date: '',

      loading: false,

      error: null,
      websiteInfo: {},
      unavailableDays: []
    }
  },
  components: { Calendar, TimeSelector, Loading },
  computed: {
    size() {
      if (this.windowWidth < 768) {
        return 'x-small'
      } else if (this.windowWidth < 992) {
        return 'small'
      } else {
        return 'large'
      }
    },
    timeArrays() {
      return {
        openingTimeSlots: opening_time_slots,
        twentyFourHourOpeningTimeSlots: twenty_four_hour_opening_time_slots
      }
    },
    windowWidth() {
      return window.innerWidth
    },
    apiPath() {
      if (this.mode == 'live') {
        return `https://${this.account}.jammed.app/api/v1`
      } else if (this.mode == 'staging') {
        return `https://${this.account}.staging.jammedapp.com/api/v1`
      } else {
        return `http://${this.account}.jammed.localhost:3000/api/v1`
      }
    },
    containerClasses() {
      if (this.wrapper == 'box') {
        return 'jammed-box jammed-has-background-white'
      } else {
        return ''
      }
    },
    friendlyDate() {
      return dayjs(this.date, 'YYYYMMDD').format('ddd D MMM')
    },
    setupApiPath() {
      return `${this.apiPath}/booking_app_setup.json`
    },
    bookingHorizonInMonths() {
      return this.websiteInfo.booking_horizon
    }
  },
  mounted() {
    this.checkForAccount()
    this.fetchDays()
  },
  methods: {
    checkForAccount() {
      if (this.account == undefined || this.account == '') {
        this.error = 'Missing Jammed account setting.\nPlease trigger this element with the account attribute, e.g. <jammed-booking account=\'abbey-road\' />'
      }
    },
    selectedDate(date) {
      this.date = date
      this.step = 2
    },
    async fetchDays () {
      if (this.error) return

      this.loading = true
      try {
        const response = await fetch(this.setupApiPath)
        const data = await response.json()
        this.websiteInfo = data.website_info
        this.unavailableDays = data.unavailable_days
      } catch(error) {
        // enter your logic for when there is an error (ex. error toast)
        console.log(error)
        this.error = `We had trouble finding the Jammed servers at ${this.setupApiPath}. Please try again, and maybe check that the account setting is correctly setup`
      } finally {
        this.loading = false
      }
    },
    changeDate() {
      this.date = ''
      this.step = 1
    }
  }
}
</script>

<style lang="scss">
// Import our special scoped & trimmed version of Bulma. All class definitions begin jammed-*. E.g. .jammed-button {}
@import url('./bulma.css');

#JammedBookingApp {
  position: relative;

  &.large {
    min-width: 600px
  }
}
</style>
