const esc = encodeURIComponent

export default {
  getTimes (apiPath, params, cb) {
    const endpoint = 'times.json'
    const queryString = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&')
    const url = `${apiPath}/${endpoint}?${queryString}`

    fetch(url)
      .then( (resp) => {
        return resp.json()
      }).then( (json) => cb(json) )
  },

  getAllDayTimes (apiPath, params, cb) {
    const endpoint = 'all_day_availability.json'
    const queryString = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&')
    const url = `${apiPath}/${endpoint}?${queryString}`

    fetch(url)
      .then( (resp) => {
        return resp.json()
      }).then( (json) => cb(json) )
  }
}
