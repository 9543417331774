<template>
  <div >
    <div v-bind:class="{ 'block-chart': !smallMode, 'block-chart-small-width': smallMode }" v-for="(set, index) in showTimes" :key="index">
      <div v-bind:style="gridStyleRow(1, timeIndex)" v-for="(time, timeIndex) in set" :key="time">
        <div
          class="time-block"
          v-bind:class="{ 'taken': timeTaken(time), 'jammed-is-clickable': !timeTaken(time), 'selected': timeSelected(time) }"
          @click="chosenTime(time)">
          {{ formattedTime(time) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export default {
  name: 'TodayBlockChart',
  props: {
    bookingTimes: {
      type: Array,
      default: function() {
        return []
      }
    },
    timeArray: {
      type: Array,
      default: function() {
        return []
      }
    },
    startTime: {
      type: String,
      optional: true,
      default: ''
    },
    smallMode: {
      type: Boolean,
      default: false
    },
    largeMode: {
      type: Boolean,
      default: false
    },
    duration: {
      type: Number,
      optional: true,
      default: 0
    },
    twelveHourTimes: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    splitArrayInto(items, size) {
      const chunks = []
      items = [].concat(...items)

      while (items.length) {
        chunks.push(
          items.splice(0, size)
        )
      }

      return chunks
    },
    timeTaken(time) {
      return this.bookingTimes.indexOf(time) >= 0
    },
    timeSelected(time) {
      if (!this.selectedTimes) return

      return this.selectedTimes.indexOf(time) >= 0
    },
    timeSplitClass(time) {
      const index = this.bookingTimes.indexOf(time)
      if (index > 0) {
        if (index % 2 == 0) {
          return 'taken-half'
        } else {
          return 'taken'
        }
      }
    },
    formattedTime(time) {
      const twelve_hour_format = 'h:mma'
      if (this.twelveHourTimes) {
        return dayjs(time, 'HH:mm').format(twelve_hour_format)
      } else {
        return time
      }
    },
    gridStyleRow(row, col) {
      return `grid-row: ${row}; grid-column: ${col+1};`
    },
    chosenTime(time) {
      this.$emit('change-start-time', time)
    }
  },
  computed: {
    showTimes() {
      if (this.smallMode) {
        return this.splitArrayInto(this.timeArray, 6)
      } else {
        return this.splitArrayInto(this.timeArray, 12)
      }
    },
    hourSlots() {
      return this.timeArray.length == 24
    },
    selectedTimes() {
      if (!this.startTime || !this.duration) return

      const timeIndex = this.timeArray.indexOf(this.startTime)

      const durationToAdd = this.hourSlots ? this.duration : this.duration * 2
      return this.timeArray.slice(timeIndex, timeIndex + durationToAdd)
    }
  }
}

</script>
<style scoped>
  .block-chart {
    display: grid;
    height: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: 18px 1fr; 
    gap: 0px 2px; 
    grid-template-areas: 
      ". . . . . . . . . . ."
      ". . . . . . . . . . ."; 
  }

  .block-chart-small-width {
    display: grid;
    height: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: 18px 1fr; 
    gap: 0px 2px; 
    grid-template-areas: 
      ". . . . . ."
      ". . . . . ."
      ". . . . . ."
      ". . . . . ."; 
  }

  .time-block {
    font-size: 10px;
    line-height: 1.6rem;
    border: 2px solid #ccc;
    text-align: center;
  }

  .taken {
    color: #888;
    background-color: #eee;
    border: 2px solid #eee;
  }
  .selected {
    color: #111;
    background-color: #00d1b2;
    border: 2px solid #00d1b2;
  }
  .taken.selected {
    background-color: hsl(347, 90%, 96%);
    border: 2px solid  hsl(347, 90%, 84%);
  }
</style>