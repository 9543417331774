export default {
  holdSlot (apiPath, data, cb) {
    const endpoint = 'booking_holds.json'
    const url = `${apiPath}/${endpoint}`
    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  },

  holdSlotWithTimes (apiPath, data, cb) {
    const endpoint = 'booking_holds/from_times.json'
    const url = `${apiPath}/${endpoint}`
    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      redirect: 'follow',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((resp) => {
      return resp.json()
    }).then((json) => cb(json))
  }
}
