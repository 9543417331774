<template>
  <div
    class="jammed-has-background-light"
    v-bind:class="{
      'jammed-has-text-grey-lighter': !available,
      'jammed-is-invisible jammed-is-hidden-mobile': invisible
      }"
    >
    <p
      v-bind:class="{
        'jammed-is-size-4': largeMode,
        'jammed-is-size-5': !largeMode && !smallMode,
        'jammed-is-size-6': smallMode,
        'jammed-pb-2 jammed-pt-2': !smallMode && doubleHeight,
      }"
      >{{ time }}</p>
    <p class="jammed-is-size-7" v-if="!smallMode">{{ price }}</p>
  </div>
</template>
<script>
export default {
  name: 'TimeElement',
  props: {
    time: {
      type: String,
      default: ''
    },
    available: {
      type: Boolean,
      default: true
    },
    invisible: {
      type: Boolean,
      default: false
    },
    price: {
      type: String,
      default: null
    },
    doubleHeight: {
      type: Boolean,
      default: false
    },
    smallMode: {
      type: Boolean,
      default: false
    },
    largeMode: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
small {
  font-size: 0.5em
}
p {
  display: block;
}
</style>