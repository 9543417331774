<template>
  <section v-if="show">
    <h2 class="jammed-title jammed-is-size-4-desktop jammed-is-size-6-touch jammed-has-text-centered">Choose activity</h2>
    <aside class="jammed-menu">
      <ul class="jammed-menu-list jammed-columns">
        <li v-for="activity in activities" :key="activity.name" class="jammed-column">
          <a
            type="radio"
            @click="selectActivity(activity)"
            class="jammed-p-4"
            v-bind:class="{'jammed-is-active jammed-is-primary': isSelectedActivity(activity), 'jammed-has-background-white-ter': !isSelectedActivity(activity)}">
            <h4 class="jammed-is-size-5-desktop jammed-is-size-6-touch jammed-is-capitalized jammed-has-text-weight-bold">{{ activity.name }}</h4>
            <p class="jammed-is-size-6-desktop jammed-is-size-6-touch jammed-has-text-weight-light">{{ activity.short_description }}</p>
          </a>
        </li>
      </ul>
    </aside>
  </section>
</template>

<script>
export default {
  name: 'ActivityPicker',
  props: {
    availableActivities: {
      type: Array,
      default: () => []
    },
    allActivities: {
      type: Array,
      required: true
    },
    selectedActivity: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      activity: ''
    }
  },
  mounted() {
    this.activity = this.selectedActivity
  },
  computed: {
    activities() {
      return this.allActivities.filter(activity => this.availableActivities.includes(activity.name))
    },
    show() {
      return this.availableActivities.length > 1
    }
  },
  methods: {
    isSelectedActivity(activity) {
      return this.selectedActivity.name == activity.name
    },
    selectActivity(activity) {
      if (this.selectedActivity == activity) return

      this.$emit('selected-activity', activity)
    },
  }
}
</script>